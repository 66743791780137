// Configuration
@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
$theme-colors: (
  'primary': $red-700,
  //$primary,
  'secondary': $yellow-300,
  //$secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
);
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/utilities';

// Layout & components
@import '../node_modules/bootstrap/scss/root';
@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/type';
@import '../node_modules/bootstrap/scss/images';
@import '../node_modules/bootstrap/scss/containers';
@import '../node_modules/bootstrap/scss/grid';
@import '../node_modules/bootstrap/scss/tables';
@import '../node_modules/bootstrap/scss/forms';
@import '../node_modules/bootstrap/scss/buttons';
@import '../node_modules/bootstrap/scss/transitions';
@import '../node_modules/bootstrap/scss/dropdown';
@import '../node_modules/bootstrap/scss/button-group';
@import '../node_modules/bootstrap/scss/nav';
@import '../node_modules/bootstrap/scss/navbar';
@import '../node_modules/bootstrap/scss/card';
@import '../node_modules/bootstrap/scss/accordion';
@import '../node_modules/bootstrap/scss/breadcrumb';
@import '../node_modules/bootstrap/scss/pagination';
@import '../node_modules/bootstrap/scss/badge';
@import '../node_modules/bootstrap/scss/alert';
@import '../node_modules/bootstrap/scss/progress';
@import '../node_modules/bootstrap/scss/list-group';
@import '../node_modules/bootstrap/scss/close';
@import '../node_modules/bootstrap/scss/toasts';
@import '../node_modules/bootstrap/scss/modal';
@import '../node_modules/bootstrap/scss/tooltip';
@import '../node_modules/bootstrap/scss/popover';
@import '../node_modules/bootstrap/scss/carousel';
@import '../node_modules/bootstrap/scss/spinners';
@import '../node_modules/bootstrap/scss/offcanvas';
@import '../node_modules/bootstrap/scss/placeholders';

// Helpers
@import '../node_modules/bootstrap/scss/helpers';

// Utilities
@import '../node_modules/bootstrap/scss/utilities/api';

.hero {
  @extend .container-fluid;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
  //@extend .container-xl;
  margin: 0;
  padding: 0;
  background-color: $orange-600;
  color: $white;
  height: 100vh;
  text-align: center;
  background-image: url('images/1H0A8068.jpg?width=1140');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  & > div {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: rgba(30, 30, 30, 0.3);
  }
}

.main {
  @extend .container;
  max-width: map-get($container-max-widths, 'md');
}

.form-message {
  display: none;
}

.cursive {
  font-family: cursive;
}